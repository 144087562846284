export class PTime {
  /**
   * Formats a given total number of minutes into a string representation of days, hours, and minutes.
   *
   * @param totalMinutes - The total number of minutes to be formatted. If not provided, defaults to '00D 00H 00M'.
   * @returns A string in the format `DDH HHM`, where `D` represents days, `H` represents hours, and `M` represents minutes.
   *
   * @example
   * // Example with 1500 minutes (equivalent to 1 day, 1 hour, and 0 minutes):
   * const result = getFormattedTotalMinutes(1500);
   * console.log(result); // Outputs: "01D 01H 00M"
   *
   * @example
   * // Example with undefined input:
   * const result = getFormattedTotalMinutes();
   * console.log(result); // Outputs: "00D 00H 00M"
   *
   * @description Converts the given total minutes into days, hours, and minutes. Pads each value with leading zeros to
   * ensure a two-digit format. If `totalMinutes` is undefined or falsy, it defaults to '00D 00H 00M'.
   */
  static getFormattedTotalMinutes(totalMinutes?: number): string {
    if (!totalMinutes) return '00D 00H 00M';

    const days = Math.floor(totalMinutes / 1440);
    const hours = Math.floor((totalMinutes % 1440) / 60);
    const minutes = totalMinutes % 60;

    const formattedDays = String(days).padStart(2, '0');
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedDays}D ${formattedHours}H ${formattedMinutes}M`;
  }

  /**
   * Delays the execution for a specified amount of time.
   * @param ms - The number of milliseconds to wait. Default is 200.
   * @returns A promise that resolves after the specified delay.
   */
  static wait = (ms = 200): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));
}

export const environment = {
  apiParq: 'https://api-dev.parqco.com/api',
  clients: {
    operator: 'https://main.d3bn372mjaphb5.amplifyapp.com',
  },
  parq: {
    serviceChargePercentage: 5,
    driverId: 760,
  },
  email: '@users.parqco.com',
  kushkiMerchantId: '1000000681457096980515794014113',
  microliths: {
    entities: 'https://api-dev.parqco.com/api/entities/v2',
    management: 'https://api-dev.parqco.com/api/management/v2',
    payments: 'https://api-dev.parqco.com/api/payments/v2',
    services: 'https://api-dev.parqco.com/api/services/v2',
    users: 'https://api-dev.parqco.com/api/users/v2',
  },
  production: false,
};
